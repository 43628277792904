import { useState, forwardRef } from "react";

import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { HiOutlineHome } from "react-icons/hi";
import { BsListTask } from "react-icons/bs";
import { IoAddCircleOutline, IoNewspaperOutline } from "react-icons/io5";
import { ThemeProvider, createTheme } from "@mui/system";

import style from "./navbar.module.css";

const theme = createTheme({
  components: {
    // Name of the component
    MuiBottomNavigationAction: {
      defaultProps: {},
    },
  },
});

const NavBar = () => {
  const [value, setValue] = useState(0);
  return (
    // <ThemeProvider theme={theme}>
    <BottomNavigation
      showLabels={true}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      className={style["nav__bar--container"]}
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        left: 0,
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // zIndex: "9999",
      }}>
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive
            ? style["nav__bar--active"]
            : style["nav__bar--link__container"]
        }>
        <HiOutlineHome className={style["nav__bar--icon"]} />
        <span className={style["nav__bar--link__title"]}>Accueil</span>
      </NavLink>
      <NavLink
        to="/articles"
        className={({ isActive }) =>
          isActive
            ? style["nav__bar--active"]
            : style["nav__bar--link__container"]
        }>
        <BsListTask className={style["nav__bar--icon"]} />
        <span className={style["nav__bar--link__title"]}>Articles</span>
      </NavLink>
      <NavLink
        to="/publish"
        className={({ isActive }) =>
          isActive
            ? style["nav__bar--active"]
            : style["nav__bar--link__container"]
        }>
        <IoAddCircleOutline className={style["nav__bar--icon"]} />
        <span className={style["nav__bar--link__title"]}>Publier</span>
      </NavLink>
      <NavLink
        to="/subscribers"
        className={({ isActive }) =>
          isActive
            ? style["nav__bar--active"]
            : style["nav__bar--link__container"]
        }>
        <IoNewspaperOutline className={style["nav__bar--icon"]} />
        <span className={style["nav__bar--link__title"]}>Abonnés</span>
      </NavLink>
    </BottomNavigation>
  );
};

export default NavBar;
