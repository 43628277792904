import { Container, Grid, Box } from "@mui/material";
import PublishForm from "../../components/PublishForm";
import globalStyle from "../../styles/global.module.css";

const PublishPage = () => {
  return (
    <Container>
      <Container className={globalStyle["container"]}>
        <h3>Publier un nouvel article</h3>
        <Box>
          <PublishForm />
        </Box>
      </Container>
    </Container>
  );
};

export default PublishPage;
