import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import style from "../Table/table.module.css";

const SubscriberItem = ({ item }) => {
  let date = new Date(item.createdAt).toLocaleString();
  return (
    <TableRow
      key={item._id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell align="left">{item.email}</TableCell>
      <TableCell align="left">{date}</TableCell>
    </TableRow>
  );
};

const SubscribersTable = ({ list, sortedBy }) => {
  // setData.sort((a, b) => {
  //   return a.lastName.toString().localeCompare(b.lastName.toString());
  // });
  // const listByName = list.sort((a, b) =>
  //   a.email.toString().localeCompare(b.email.toString()),
  // );

  // console.log(
  //   list.sort((a, b) => a.toString().localeCompare(b.toString())),
  // );

  // 👇️ sort by String property ASCENDING (A - Z)
  const strAscending = [...list].sort((a, b) => (a.email > b.email ? 1 : -1));
  

  // 👇️ sort by String property DESCENDING (Z - A)
  const strDescending = [...list].sort((a, b) => (a.email > b.email ? -1 : 1));
  

  return (
    <div>
      <TableContainer
        component={Paper}
        className={style["article__table--container"]}>
        <Table sx={{ minWidth: 400 }} aria-label="customized table">
          <TableHead className={style["article__table--head"]}>
            <TableRow className={style["article__table--row"]}>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Date de souscription</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedBy === "A-Z" &&
              strAscending.map((row) => (
                <SubscriberItem item={row} key={row._id} />
              ))}
            {sortedBy === "Z-A" &&
              strDescending.map((row) => (
                <SubscriberItem item={row} key={row._id} />
              ))}
            {/* {list.map((row) => (
              <SubscriberItem item={row} key={row._id} />
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SubscribersTable;
