import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/categories/`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.data);
  },
);


export const categorieSlice = createSlice({
  name: "categories",
  initialState: {
    items: [],
    isLoading: false,
    error: false,
  },
  reducers: {
    getThis(state, _action) {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: false,
          items: action.payload,
        };
      })
      .addCase(getCategories.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          error: true,
        };
      });
  },
});

export default categorieSlice;