import { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import ArticleTable from "../../components/Table";
import { getArticles } from "../../store/feature/articles/articleSlice";
import Loader from "../../components/Loader";
import globalStyle from "../../styles/global.module.css";
const ArticlesPage = () => {
  const articles = useSelector((state) => state.articles);

  const blockContent = articles.blocks;

  // Specifically mark `dispatch` as being a type that understands thunks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  return (
    <Container>
      <Container className={globalStyle["container"]}>
        {articles.isLoading ? (
          <Loader />
        ) : (
          <>
            <h2>Tous les articles</h2>
            <Grid>
              <ArticleTable list={articles.items} />
            </Grid>
          </>
        )}
      </Container>
    </Container>
  );
};

export default ArticlesPage;
