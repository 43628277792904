import { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

// Editor JS
import { createReactEditorJS } from "react-editor-js";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import ImageTool from "@editorjs/image";
// editor js
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { BiImageAdd } from "react-icons/bi";
import axios from "axios";
import { setBlockContent } from "../../store/feature/articles/articleSlice";
import {
  publishArticle,
  setFeaturedImage,
} from "../../store/feature/articles/articleSlice";

import style from "./publishform.module.css";

const PublishForm = () => {
  const featuredImage = useSelector((state) => state.articles.featuredImage);
  const [title, setTitle] = useState("");
  const [editorContent, setEditorContent] = useState([]);

  const [file, setFile] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);

  const dispatch = useDispatch();

  const onFileUploadChange = (e) => {
    const fileInput = e.target;

    if (!fileInput.files) {
      alert("No file was chosen");
      return;
    }

    if (!fileInput.files || fileInput.files.length === 0) {
      alert("Files list is empty");
      return;
    }

    const file = fileInput.files[0];

    /** File validation */
    if (!file.type.startsWith("image")) {
      alert("Please select a valide image");
      return;
    }

    /** Setting file state */
    setFile(file); // we will use the file state, to send it later to the server
    setPreviewUrl(URL.createObjectURL(file)); // we will use this to show the preview of the image

    /** Reset file input */
    e.currentTarget.type = "text";
    e.currentTarget.type = "file";
  };

  const handleCancel = (event) => {
    event.target.reset();
    setPreviewUrl("");
  };

  const [value, setValue] = useState("");

  // Create react editor instance
  const ReactEditorJS = createReactEditorJS();

  const instanceRef = useRef(null);

  const editorCore = useRef(null);

  // initialiaze editor
  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  // Save on change "React Editor JS"
  const handleSave = useCallback(async () => {
    const savedData = await editorCore.current.save();

    setEditorContent(savedData.blocks);

    dispatch(setBlockContent(savedData));
  }, []);

  // Sanitizer
  const sanitizerConfig = {
    b: {}, // leave <b> without any attributes
    p: true, // leave <p> as is
    a: {
      href: true, // leave <a> with href
      target: "_blank", // add 'target="_blank"'
    },
    img: {
      alt: false, // leave <img> without any attributes
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("upload_preset", "zaqnqwv4");

    toast.promise(
      axios({
        method: "POST",
        url: " https://api.cloudinary.com/v1_1/dhyk7zned/image/upload",
        data: formData,
      })
        .then((response) => {
          // dispatch(setFeaturedImage(response.data.url));
          axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/api/articles/add`,
            headers: {
              "Content-Type": "application/json",
              Accept:
                "image/jpeg, image/png, image/gif, application/json, text/plain, */*",
            },

            data: {
              title: title,
              file: file,
              content: editorContent,
              authorId: "63ad6d3553173a71d388bc6d",
              principal_image: response.data.url,
            },
          })
            .then((response) => response.data.article)
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error)),

      {
        pending: "Publication  en cours d'envoi!",
        success: "Nouvelle pulication ajoutée !",
        error: "Une erreur s'est produite lors de l'ajout !",
      },
    );
    setEditorContent([]);
    event.target.reset();
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        onReset={handleCancel}
        encType="multipart/form-data">
        <TextField
          id="outlined-basic"
          label="Titre"
          variant="outlined"
          sx={{ width: "100%", background: "white", margin: ".5em 0" }}
          onChange={(event) => setTitle(event.target.value)}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            border: ".1em solid #C4C4C4",
          }}>
          <ReactEditorJS
            defaultValue={value}
            onInitialize={handleInitialize}
            onChange={handleSave}
            sanitizer={sanitizerConfig}
            tools={{
              header: Header,
              list: List,
              quote: Quote,
              image: {
                class: ImageTool,
                inlineToolbar: true,
                config: {
                  endpoints: {
                    byFile: `${process.env.REACT_APP_API_URL}/api/upload/content-image`, // Your backend file uploader endpoint
                  },
                  captionPlaceholder: "image",
                  caption: "image",
                },
              },
            }}
            placeholder="Let`s write an awesome story!"
            data={editorContent}
            instanceRef={(instance) => (instanceRef.current = instance)}
            className={style["publish__form--editor"]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}>
          {previewUrl ? (
            <div className="mx-auto w-80">
              <img
                alt="file uploader preview"
                src={previewUrl}
                width={320}
                height={320}
              />
            </div>
          ) : (
            <div>
              <label
                htmlFor="image"
                className={style["publish__form--upload__container"]}>
                <BiImageAdd />
                Ajouter une photo
              </label>
              <input
                name="file"
                type="file"
                id="image"
                style={{ display: "none" }}
                onChange={onFileUploadChange}
              />
            </div>
          )}
          {previewUrl ? (
            <div className={style["publish__form--action__container"]}>
              <label
                className={style["publish__form--upload__container"]}
                htmlFor="image">
                <BiImageAdd />
                Modifier la photo
              </label>
              <input
                name="file"
                type="file"
                id="image"
                style={{ display: "none" }}
                onChange={onFileUploadChange}
              />

              <button
                onClick={() => setPreviewUrl("")}
                className={style["publish__form--remove__picture"]}>
                Retirer la photo
              </button>
            </div>
          ) : null}
        </Box>
        <div className={style["publish__form--action__container"]}>
          <input
            type="reset"
            value="Annuler"
            className={style["publish__form--cancel__button"]}
          />
          <input
            type="submit"
            value="Publier"
            className={style["publish__form--submit__button"]}
          />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PublishForm;
