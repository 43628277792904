import { Container, Grid } from "@mui/material";
import EditForm from "../../components/EditForm";
import globalStyle from "../../styles/global.module.css";

const EditArticlePage = () => {
  return (
    <Container className={globalStyle["container"]}>
      <EditForm />
    </Container>
  );
};

export default EditArticlePage;
