/* eslint-disable no-unused-vars */

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export const getArticles = createAsyncThunk(
  "articles/getArticles",
  async () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/articles/`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.data.articles);
  },
);

export const getAnArticle = createAsyncThunk(
  "articles/getAnArticle",
  async (id) => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/articles/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.data.article);
  },
);

export const publishArticle = createAsyncThunk(
  "articles/publishArticle",
  async (content) => {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/articles/add`,
      headers: {
        "Content-Type": "application/json",
      },
      data: content,
    }).then((response) => response.data.article);
  },
);

export const articleSlice = createSlice({
  name: "articles",
  initialState: {
    items: [],
    isLoading: false,
    error: false,
    isSaving: false,
    singleArticle: {},
    blocks: {},
    featuredImage: "",
    isLoadingSingle: false
  },
  reducers: {
    getThis(state, action) {
      return state;
    },
    setFeaturedImage(state, action) {
      return {
        ...state,
        featuredImage: action.payload
      };
    },
    setBlockContent(state, action) {
      return {
        ...state,
        blocks: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticles.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getArticles.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          error: false,
          items: action.payload,
        };
      })
      .addCase(getArticles.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          error: true,
        };
      })
      .addCase(publishArticle.pending, (state) => {
        return {
          ...state,
          isSaving: true,
        };
      })
      .addCase(publishArticle.fulfilled, (state) => {
        return {
          ...state,
          isSaving: false,
          error: false,
        };
      })
      .addCase(publishArticle.rejected, (state) => {
        return {
          ...state,
          isSaving: false,
          error: true,
        };
      })
      .addCase(getAnArticle.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          isLoadingSingle: true
        };
      })
      .addCase(getAnArticle.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isLoadingSingle: false,
          singleArticle: action.payload,
        };
      })
      .addCase(getAnArticle.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          isLoadingSingle: false,
          error: true,
        };
      });
  },
});

export const { setBlockContent, setFeaturedImage } = articleSlice.actions;

export default articleSlice;
