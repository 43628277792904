/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { setAuth } from "../../store/feature/user/userSlice";
import style from "./login.module.css";


const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event
  ) => {
    event.preventDefault();
  };

  const handleLogin = (event) => {
    event.preventDefault();

    // axios({
    //   method: "POST",
    //   url: `${process.env.NEXT_PUBLIC_API_URL}/api/author/login`,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: {
    //     email: email,
    //     password: password,
    //   },
    // })
    //   .then((response) => {
    //     localStorage.setItem("blog-token", "response.data.token");

    //     localStorage.setItem(
    //       "logged-user-pseudo",
    //       response.data.payload.pseudo,
    //     );
    //     localStorage.setItem("logged-user-id", response.data.payload.id);
    //     dispatch(setId(response.data.payload.id));
    //     dispatch(setLoggedUserMail(response.data.payload.email));
    //     dispatch(setLoggedUserPseudo(response.data.payload.pseudo));
    //   })

    //   .catch((erro) => {
    //     dispatch(setError(true));
    //   });
    dispatch(setAuth(true));
    localStorage.setItem("blog-token", "response.data.token");

    
    if (!user.error) {
      dispatch(setAuth(true));
      localStorage.setItem("blog-token", "response.data.token");
    }

    event.target.reset();
  };
  return (
    <div className={style["login__page--container"]}>
      <div className={style["login__page--image__container"]}>
        <img src="/logo.png" alt="website logo" />
      </div>
      <form onSubmit={handleLogin}>
        <TextField
          id="outlined-basic"
          label="email"
          variant="outlined"
          className={style["login__page--input"]}
          onChange={ (event) => setEmail(event.target.value) }
          sx={{margin: "1em 0"}}
        />
        <section>
          <FormControl
            sx={{ m: 1, width: "25ch" }}
            variant="outlined"
            className={style["login__page--input"]}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </section>
        <section className={style["login__page--action__container"]}>
          <input
            type="submit"
            value="Se connecter"
            className={style["login__page--action__button"]}
          />
          <p>
            Pas de compte ?<Link to="/sign-up">Cliquez ici</Link>
          </p>
        </section>
      </form>
    </div>
  );
};

export default LoginPage;
