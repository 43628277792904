import { configureStore } from "@reduxjs/toolkit";
import { articleSlice } from "./feature/articles/articleSlice";
import { subscriberSlice } from "./feature/subscribers/subscriberSlice";
import { categorieSlice } from "./feature/categories/categorieSlice";
import { userSlice } from "./feature/user/userSlice";

export const store = configureStore({
  reducer: {
    articles: articleSlice.reducer,
    subscribers: subscriberSlice.reducer,
    categories: categorieSlice.reducer,
    user: userSlice.reducer,
  },
});
