import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@mui/material";

import SubscribersTable from "../../components/SubscribersTable";
import { getSubscribers } from "../../store/feature/subscribers/subscriberSlice";
import globalStyle from "../../styles/global.module.css";


const SubscribersPage = () => {
  const subscribers = useSelector((state) => state.subscribers);

  // Specifically mark `dispatch` as being a type that understands thunks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscribers());
  }, [dispatch]);
  return (
    <Container>
      
      <Container className={globalStyle["container"]}>
        <h2>Tous les abonnés</h2>
        <Grid>
          <SubscribersTable list={subscribers.items} />
        </Grid>
      </Container>

    </Container>
  );
};

export default SubscribersPage;