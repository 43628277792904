import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Grid } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { getAnArticle } from "../../store/feature/articles/articleSlice";
import Loader from "../../components/Loader";
import globalStyle from "../../styles/global.module.css";
import style from "./singleArticle.module.css";
import "react-toastify/dist/ReactToastify.css";

const SingleArticle = () => {
  const { id } = useParams();
  const articles = useSelector((state) => state.articles);

  // Specifically mark `dispatch` as being a type that understands thunks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnArticle(id));
  }, [dispatch, id]);
  return (
    <Container>
      <Container className={globalStyle["container"]}>
        {articles.isLoading   ? (
          <Loader />
        ) : (
          <Grid>
            <h2>{articles.singleArticle.title}</h2>
            <div className={style["article-image"]}>
              <img
                src={articles.singleArticle.principal_image}
                alt="article visuel"
              />
            </div>
            {/* {articles.singleArticle.content.map((block) => (
              <>
         

                {block.type === "header" && <h3>{block.data.text}</h3>}
                {block.type === "paragraph" && <p>{block.data.text}</p>}
                {block.type === "list" && (
                  <ol>
                    {block.data.items.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ol>
                )}
                {block.type === "image" && (
                  <div style={{ width: "200px", height: "200px" }}>
                    <img src={block.data.file.url} alt="image" />
                  </div>
                )}
              </>
            ))} */}

            {articles.singleArticle.content.map((text) => {
              <code
                dangerouslySetInnerHTML={{
                  __html: text.data.text,
                }}
              />;
            })}

            <h4>Publié le : {articles.singleArticle.createdAt} </h4>

            <div className={style["single__article--action__container"]}>
              <button
                type="button"
                className={style["single__article--delete__button"]}
                onClick={() => {
                  toast.promise(
                    axios({
                      method: "DELETE",
                      url: `${process.env.REACT_APP_API_URL}/api/articles/${id}`,
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }).then((response) => console.log(response.data)),
                    {
                      pending: "Suppression en cours !",
                      success: "Suppression réussie avec succès !",
                      error: "Une erreur s'est produite de la suppression !",
                    },
                    {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    },
                  );
                }}>
                Supprimer
              </button>
              <button
                type="button"
                className={style["single__article--edit__button"]}>
                <Link to={`/article/edit/${id}`}>Modifier</Link>
              </button>
            </div>
          </Grid>
        )}
        <ToastContainer />
      </Container>
    </Container>
  );
};

export default SingleArticle;
