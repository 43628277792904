/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */

import { useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import HomePage from "./pages/home/";
import LoginPage from "./pages/login";
import NotFound from "./pages/not-found";
import SingleArticle from "./pages/single-article";
import ArticlesPage from "./pages/articles";
import RegisterPage from "./pages/register";
import Header from "./components/Header";
import NavBar from "./components/NavBar";
// import { setAuth } from "./store/features/user/userSlice";
import { setAuth } from "./store/feature/user/userSlice";

import "./App.css";
import SubscribersPage from "./pages/subscribers";
import PublishPage from "./pages/publish";
import EditArticlePage from "./pages/edit-article";

const App = () => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  let token = localStorage.getItem("blog-token");

  useEffect(() => {
    if (token) {
      dispatch(setAuth(true));
    }
  }, [dispatch]);
  return (
    <>
      {token && user.isLogged ? <Header /> : null}
      {token && user.isLogged ? <NavBar /> : null}
      <Routes>
        {!token && !user.isLogged ? (
          <>
            <Route index path="/" element={<LoginPage />} />

            <Route
              path="/*"
              element={
                token && user.isLogged ? (
                  <NotFound />
                ) : (
                  <Navigate replace to="/" />
                )
              }
            />
          </>
        ) : (
          <>
            <Route path="/" index element={<HomePage />} />
            <Route path="/articles" element={<ArticlesPage />} />
            <Route path="/subscribers" element={<SubscribersPage />} />
            <Route path="/publish" element={<PublishPage />} />
            <Route path="/article/edit/:id" element={<EditArticlePage />} />
            <Route path="/article/:id" element={<SingleArticle />} />
            <Route path="/sign-up" element={<RegisterPage />} />
          </>
        )}
      </Routes>

      <Outlet />
    </>
  );
};

export default App;
