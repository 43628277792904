/* eslint-disable no-unused-vars */

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export const getSubscribers = createAsyncThunk(
  "subscribers/getSubscribers",
  async () => {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/api/newsletters/`,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => response.data.newsletter);
  },
);



export const subscriberSlice = createSlice({
  name: "subscribers",
  initialState: {
    items: [],
    isLoading: false,
    error: false,
  },
  reducers: {
    getThis(state, _action) {
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscribers.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(
        getSubscribers.fulfilled,
        (state, action) => {
          return {
            ...state,
            isLoading: false,
            error: false,
            items: action.payload,
          };
        },
      )
      .addCase(getSubscribers.rejected, (state) => {
        return {
          ...state,
          isLoading: false,
          error: true,
        };
      });
  },
});

export default subscriberSlice;
