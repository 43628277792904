import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ArticleTable from "../../components/Table";
import SubscribersTable from "../../components/SubscribersTable";
import { getArticles } from "../../store/feature/articles/articleSlice";
import { getSubscribers } from "../../store/feature/subscribers/subscriberSlice";
import style from "./home.module.css";
import globalStyle from "../../styles/global.module.css";

const HomePage = () => {
  const articles = useSelector((state) => state.articles);

  const subscribers = useSelector((state) => state.subscribers);

  // Specifically mark `dispatch` as being a type that understands thunks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getArticles());
    dispatch(getSubscribers());
  }, [dispatch]);

   const [sortedBy, setSortedBy] = useState("A-Z");

  const handleChange = (event) => {
    setSortedBy(event.target.value);
  };

  return (
    <Container>
      <Container className={globalStyle["container"]}>
        <Grid className={style["section__container--heading"]}>
          <h2 className={style["title"]}>Dernies articles</h2>
         
        </Grid>

        <ArticleTable list={articles.items} />
        <section className={style["section__container--table__wrapper"]}>
          <Link
            to="/articles"
            className={style["section__container--see__all__link"]}>
            Voir tous les articles{" "}
          </Link>
        </section>

        <Grid className={style["section__container--heading"]}>
          <h2 className={style["title"]}>Abonnés à la newsletter</h2>
          <FormControl sx={{ width: "40%", fontSize: ".8em", height: "20px" }}>
            <InputLabel id="demo-simple-select-label">Trier par</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sortedBy}
              label="Trier par"
              onChange={handleChange}>
              <MenuItem value={"A-Z"}>A-Z</MenuItem>
              <MenuItem value={"Z-A"}>Z-A</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid>
          <SubscribersTable list={subscribers.items} sortedBy={sortedBy} />
          <section className={style["section__container--table__wrapper"]}>
            <Link
              to="/subscribers"
              className={style["section__container--see__all__link"]}>
              Voir tous les abonnés
            </Link>
          </section>
        </Grid>
      </Container>
    </Container>
  );
};

export default HomePage;
