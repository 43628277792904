import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { styled } from "@mui/material/styles";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BiTrash, BiEdit } from "react-icons/bi";
import style from "./table.module.css";
import "react-toastify/dist/ReactToastify.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableItem = ({ item }) => {
  let textReduced = item.content[0].data.text.slice(
    20,
    item.content[0].data.text.length,
  );

  let date = new Date(item.createdAt).toLocaleString();

  return (
    <TableRow
      key={item._id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell
        component="th"
        scope="row"
        className={style["table__item--cell"]}
        sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
        <Link to={`/article/${item._id}`}>
          <div className={style["image__container"]}>
            <img src={item.principal_image} alt="article visuel image" />
          </div>
        </Link>
      </TableCell>
      <TableCell
        align="left"
        className={style["table__item--cell"]}
        sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}>
        <Link to={`/article/${item._id}`} style={{ fontSize: ".9em" }}>
          {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
        </Link>
      </TableCell>
      <TableCell align="left" className={style["table__item--cell"]}>
        <Link to={`/article/${item._id}`}>
          {item.content[0] && (
            <code
              dangerouslySetInnerHTML={{
                __html: item.content[0].data.text.replace(textReduced, "..."),
              }}
            />
          )}
        </Link>
      </TableCell>
      {/* <TableCell align="left" className={style["table__item--cell"]}>
        <Link to={`/article/${item._id}`}>
          {item.categories.map((category, index) =>
            index !== item.categories.length - 1
              ? category.name + ","
              : category.name,
          )}
        </Link>
      </TableCell> */}
      <TableCell align="left" className={style["table__item--cell"]}>
        <Link to={`/article/${item._id}`}>{date}</Link>
      </TableCell>
      <TableCell
        align="left"
        className={style[("table__item--cell", "table__item--user__actions")]}>
        <Link
          to={`/article/edit/${item._id}`}
          className={style["table__item--edit"]}>
          <BiEdit />
        </Link>
        <BiTrash
          className={style["table__item--delete"]}
          onClick={() => {
            toast.promise(
              axios({
                method: "DELETE",
                url: `${process.env.REACT_APP_API_URL}/api/articles/${item._id}`,
                headers: {
                  "Content-Type": "application/json",
                },
              }).then((response) => console.log(response.data)),
              {
                pending: "Suppression en cours !",
                success: "Suppression réussie avec succès !",
                error: "Une erreur s'est produite de la suppression !",
              },
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              },
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export const ArticleTable = ({ list }) => {
  return (
    <div>
      <TableContainer
        component={Paper}
        className={style["article__table--container"]}>
        <Table sx={{ minWidth: 800 }} aria-label="customized table">
          <TableHead className={style["article__table--head"]}>
            <TableRow className={style["article__table--row"]}>
              <TableCell sx={{ width: 200 }}>Photo/Vidéo</TableCell>
              <TableCell align="left" sx={{ width: 400 }}>
                Titre
              </TableCell>
              <TableCell align="left" sx={{ width: 300 }}>
                Texte
              </TableCell>
              <TableCell align="left" sx={{ width: 300 }}>
                Date de publication
              </TableCell>
              <TableCell align="left" sx={{ width: 300 }}>
                Actions Utilisateurs
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableItem item={row} key={row._id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ToastContainer limit={1} />
    </div>
  );
};

export default ArticleTable;
