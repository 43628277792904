import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { BiImageAdd } from "react-icons/bi";
// import { getAnArticle } from "../../store/features/articles/articleSlice";
import { getAnArticle } from "../../store/feature/articles/articleSlice";
import style from "../../components/PublishForm/publishform.module.css";
import Loader from "../Loader";

const EditForm = () => {
  const { id } = useParams();
  const article = useSelector((state) => state.articles.singleArticle);
  const loading = useSelector((state) => state.articles.isLoading);

  // Specifically mark `dispatch` as being a type that understands thunks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnArticle(id));
  }, [dispatch]);

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [categorie, setCategorie] = useState([]);
  const [photo, setPhoto] = useState("");
  const [video, setVideo] = useState("");

  const [file, setFile] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);

  const onFileUploadChange = (e) => {
    const fileInput = e.target;

    if (!fileInput.files) {
      alert("No file was chosen");
      return;
    }

    if (!fileInput.files || fileInput.files.length === 0) {
      alert("Files list is empty");
      return;
    }

    const file = fileInput.files[0];

    /** File validation */
    if (!file.type.startsWith("image")) {
      alert("Please select a valide image");
      return;
    }

    /** Setting file state */
    setFile(file); // we will use the file state, to send it later to the server
    setPreviewUrl(URL.createObjectURL(file)); // we will use this to show the preview of the image

    /** Reset file input */
    e.currentTarget.type = "text";
    e.currentTarget.type = "file";
  };
  return (
    <Container>
      <h2>Modifier un article</h2>
      <Grid>
        {loading ? (
          <Loader />
        ) : (
          <form>
            <TextField
              id="outlined-basic"
              label="Titre"
              variant="outlined"
              sx={{ width: "100%", background: "white", margin: ".5em 0" }}
              onChange={(event) => setTitle(event.target.value)}
              value={article.title}
            />
            <h3>
              Catégories :
              <span>
                {article.categories.map((option, index) =>
                  index !== categorie.length - 1
                    ? option.name + ","
                    : option.name,
                )}
              </span>
            </h3>
            <TextField
              id="outlined-select-currency"
              select
              label="Categories"
              helperText=""
              sx={{ width: "100%", background: "white", margin: ".5em 0" }}>
              <FormControl
                sx={{ m: 3 }}
                component="fieldset"
                variant="standard">
                <FormGroup>
                  {article.categories.map((option, index) => (
                    <FormControlLabel
                      key={option._id}
                      control={
                        <Checkbox
                          //   checked={checkedState[index]}
                          //   onChange={() => handleOnChange(index, option._id)}
                          name={option.name}
                        />
                      }
                      label={option.name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </TextField>
            <TextField
              id="outlined-multiline-static"
              label="Texte"
              value={article.text}
              multiline
              rows={6}
              placeholder="Écrivez ici"
              sx={{ width: "100%", background: "white", margin: ".5em 0" }}
              onChange={(event) => setText(event.target.value)}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}>
              {previewUrl ? (
                <div className="mx-auto w-80">
                  <img
                    alt="file uploader preview"
                    src={article.photo}
                    width={120}
                    height={120}
                  />
                </div>
              ) : (
                <div>
                  <label
                    htmlFor="image"
                    className={style["publish__form--upload__container"]}>
                    <BiImageAdd />
                    Ajouter une photo
                  </label>
                  <input
                    name="file"
                    type="file"
                    id="image"
                    style={{ display: "none" }}
                    onChange={onFileUploadChange}
                  />
                </div>
              )}
              {previewUrl ? (
                <div className={style["publish__form--action__container"]}>
                  <label
                    className={style["publish__form--upload__container"]}
                    htmlFor="image">
                    <BiImageAdd />
                    Modifier la photo
                  </label>
                  <input
                    name="file"
                    type="file"
                    id="image"
                    style={{ display: "none" }}
                    onChange={onFileUploadChange}
                    className={style["publish__form--submit__button"]}
                  />

                  <button
                    onClick={() => setPreviewUrl("")}
                    className={style["publish__form--remove__picture"]}>
                    Retirer la photo
                  </button>
                </div>
              ) : null}
            </Box>

            <TextField
              id="outlined-basic"
              label="Ajouter une vidéo"
              variant="outlined"
              placeholder="Mettez un lien ici"
              value={article.video}
              sx={{ width: "100%", background: "white", margin: ".5em 0" }}
              onChange={(event) => setVideo(event.target.value)}
            />
            <div className={style["publish__form--action__container"]}>
              <input
                type="reset"
                value="Annuler"
                className={style["publish__form--cancel__button"]}
              />
              <input
                type="submit"
                value="Enregistrer"
                className={style["publish__form--submit__button"]}
              />
            </div>
          </form>
        )}
      </Grid>
    </Container>
  );
};

export default EditForm;
