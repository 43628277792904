import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../store/feature/user/userSlice";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlineUserCircle } from "react-icons/hi";
import { AiOutlineLogout } from "react-icons/ai";
import style from "./headerStyle.module.css";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const logout = () => {
    // Perform localStorage action
    localStorage.removeItem("blog-token");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loggedUser, setLoggedUser] = useState(
    localStorage.getItem("logged-user-pseudo"),
  );

  // setLoggedUser(window.localStorage.getItem("logged-user-pseudo"));

  return (
    <AppBar>
      <Toolbar className={style.toolbar}>
        <div className={style["logo__container"]}>
          <img src="/logo.png" alt="website logo" />
        </div>
        <Grid item xs={6}>
          <Button
            id="fade-button"
            aria-controls={open ? "fade-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<FiChevronDown />}>
            {loggedUser}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}>
            <MenuItem
              onClick={handleClose}
              className={style["menu__item--container"]}>
              <HiOutlineUserCircle className={style["menu__item--icon"]} />
              <span className={style.span}>Modifier le profil</span>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              className={style["menu__item--container"]}>
              <AiOutlineLogout
                className={style["menu__item--icon"]}
                onClick={() => {
                  logout();
                  dispatch(setAuth(false));
                }}
              />
              <span
                className={style.span}
                onClick={() => {
                  logout();
                  dispatch(setAuth(false));
                }}>
                Se déconnecter
              </span>
            </MenuItem>
          </Menu>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
