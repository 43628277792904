/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable radix */

import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    info: {},
    id: "",
    pseudo: "",
    email: "",
    isLogged: false,
    error: false,
  },
  reducers: {
    setAuth(state, action) {
      return {
        ...state,
        isLogged: action.payload,
      };
    },
    setId(state, action) {
      return {
        ...state,
        id: action.payload,
      };
    },
    setLoggedUserMail(state, action) {
      return {
        ...state,
        email: action.payload,
      };
    },
    setLoggedUserPseudo(state, action) {
      return {
        ...state,
        pseudo: action.payload,
      };
    },
    setError(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export const {
  setAuth,
  setId,
  setLoggedUserMail,
  setLoggedUserPseudo,
  setError,
} = userSlice.actions;

export default userSlice;
